<template>
  <transition name="fadeIn">
    <div
      class="preloader"
      v-if="showHide"
      :class="[className, { 'preloader-transparent': transparent }]"
    >
      <div class="preloader-box">
        <img src="@/assets/preloader.png" alt="preloader" />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from "vue";

export default {
  name: "nw-preloader",
  setup(props) {
    const className = computed(() => {
      return ["preloader-" + props.size];
    });
    return {
      className
    };
  },
  props: {
    size: {
      type: String,
      required: false,
      default: "xl", // lg md sm xs
      validator: prop => ["xl", "lg", "md", "sm", "xs"].includes(prop)
    },
    showHide: {
      type: Boolean,
      default: true
    },
    transparent: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.preloader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;

  .preloader-box {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      animation: spin 3s linear infinite;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.preloader-xl {
    .preloader-box {
      width: 48px;
      height: 48px;
    }
  }

  &.preloader-lg {
    .preloader-box {
      width: 40px;
      height: 40px;
    }
  }

  &.preloader-md {
    .preloader-box {
      width: 32px;
      height: 32px;
    }
  }

  &.preloader-sm {
    .preloader-box {
      width: 24px;
      height: 24px;
    }
  }

  &.preloader-xs {
    .preloader-box {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
