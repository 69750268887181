<template>
	<div class="home">
		<video playsinline autoplay muted loop :poster="sImageURL">
			<source :src="sVideoURL" type="video/mp4">
			Your browser does not support the video tag.
		</video>

		<sidebar
			:class="{'is-active': !bVisibleSidebar}"
			:step="iCurrStep"
			@changeStep="onChangeStep"
		/>

		<div class="content">
			<div class="content__inner">
				<template v-if="iCurrStep === 0">
					<div class="content__title">Looking <br /> <b>for a <span>Couple</span>?</b></div>
					<p class="content__subtitle">We are ready to help you!</p>
					<button type="button" class="custom-btn s1 wide" @click="onShowSidebar">Join Us</button>
				</template>
				<template v-else-if="iCurrStep === 1">
					<div class="content__photos">
						<img src="@/assets/t1.jpg" width="114" height="114" alt="girl1" />
						<img src="@/assets/t2.jpg" width="114" height="114" alt="girl2" />
						<img src="@/assets/t3.jpg" width="114" height="114" alt="girl3" />
					</div>
					<div class="content__title s1">More than <br /> <b><span>7 000 single girls</span></b></div>
				</template>
				<template v-else-if="iCurrStep === 2">
					<div class="content__title s1">International <br /> <b><span>dating platform</span></b></div>
					<p class="content__subtitle">Girls from 47 countries</p>
				</template>
				<template v-else-if="iCurrStep === 3">
					<div class="content__title s2">Possibility to realize <br /> <b><span>a real meeting</span></b></div>
				</template>
				<template v-else>
					<div class="content__title s1">All our users <br /> are <b><span>verified</span></b></div>
					<p class="content__subtitle">We care about our clients and guarantee <br /> the security of personal data</p>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';

export default {
	name: 'home',

	components: {
		'sidebar': Sidebar
	},

	data: function ()
	{
		var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
			index = Math.floor(Math.random() * 5) + 1;

		return {
			sVideoURL: !isMobile ? require('@/assets/videos/'+ index +'.mp4') : null,
			sImageURL: require('@/assets/videos/'+ index +'.jpg'),
			iCurrStep: 0,
			bVisibleSidebar: true
		}
	},

	methods: {
		onChangeStep: function ()
		{
			this.iCurrStep++;
		},

		onShowSidebar: function ()
		{
			this.bVisibleSidebar = false;
		}
	}
}
</script>

<style lang="scss">
@import '../styles/style';

.home
{
	position: relative;
	overflow: hidden;
	height: 100vh;

	video
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		&::-webkit-media-controls,
		&::-moz-media-controls,
		&::-o-media-controls,
		&::-ms-media-controls,
		&::-webkit-media-controls-play-button
		{
			display: none;
		}
	}
}

.content
{
	height: 100%;
	text-align: center;
	overflow: hidden;

	&::after
	{
		content: '';
		display: inline-block;
		vertical-align: bottom;
		width: 0;
		height: 100%;
	}

	&::before
	{
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 150%;
		height: 100%;
		background-color: rgba(#000, 0.3);
	}

	&__inner
	{
		position: relative;
		display: inline-block;
		vertical-align: bottom;
		width: 100%;
		padding: 0 7vw 8vh;
		line-height: 1.4;
		font-family: 'Gilroy';
		text-align: left;
		color: #FFF;
		z-index: 1;
	}

	&__title
	{
		line-height: 1;
		font-size: 4.5rem;

		span
		{
			color: #35E3CE;
		}
	}

	&__subtitle
	{
		margin-top: 10px;
		font-size: 2rem;
	}

	&__photos
	{
		margin-bottom: 30px;
		font-size: 0;

		img
		{
			display: inline-block;
			vertical-align: middle;
			max-width: 100%;
			height: auto;
			margin-left: -30px;
			border-radius: 50%;

			&:first-child
			{
				margin-left: 0;
			}
		}
	}
	
	.custom-btn
	{
		display: block;
		max-width: 300px;
		margin: 35px auto 0;
	}

	@media (min-width: 768px)
	{
		&__inner
		{
			padding-bottom: 10vh;
		}

		&__title
		{
			font-size: 9rem;
		}

		&__subtitle
		{
			font-size: 3rem;
		}
	}

	@media (min-width: 992px)
	{
		text-align: left;

		&__title
		{
			font-size: 16rem;
			font-size: 8.2vw;

			&.s1
			{
				font-size: 13rem;
				font-size: 6.75vw;
			}

			&.s2
			{
				font-size: 12rem;
				font-size: 6.25vw;
			}
		}		

		&__subtitle
		{
			margin-top: 0;
			font-size: 4.5rem;
		}

		.custom-btn
		{
			display: none;
		}
	}
}
</style>
